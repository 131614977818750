import "./CustomerManagementComponent.scss";
import React, {useMemo, useState} from "react";
import {ITableColumn} from "../../../shared/models/table.model";
import {CommonService} from "../../../shared/services";
import LinkComponent from "../../../shared/components/link/LinkComponent";
import {APIConfig, ImageConfig} from "../../../constants";
import SearchComponent from "../../../shared/components/search/SearchComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import TableWrapperComponent from "../../../shared/components/table-wrapper/TableWrapperComponent";
import DialogComponent from "../../../shared/components/dialog-component/DialogComponent";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../../store/reducers";

const CustomerManagementListComponent = () => {
    const [filterData, setFilterData] = useState<any>({
        search: '',
        // sort:{},
    });
    const {currentUser}=useSelector((state:IRootReducerState)=>state.account);
    const [imagePreview, setImagePreview] = useState<any>();
    const [imagePreviewUrl, setImagePreviewUrl] = useState<any>();
    const CustomerManagementListColumn: ITableColumn[] = useMemo<ITableColumn[]>(() => {
        return [
            {
                title: "Customer Logo",
                key: 'user_name',
                dataIndex: 'user_management_name',
                fixed: 'left',
                // sortable: true,
                width: 350,
                render: (item: any) => {
                    return <div>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'d-flex ts-justify-content-center ts-align-items-center icon-button'}
                             onClick={() => {
                                 setImagePreview(true)
                                 setImagePreviewUrl(item?.doc_details?.url)
                             }}>
                            <img className={'logo-customer-container mrg-right-5'} height={25} width={25}
                                 src={item?.doc_details?.url} alt=""/>
                            <div className={"underline-text"}>
                                {item?.logo_name || item?.doc_details?.name}
                            </div>
                        </div>
                    </div>;
                }
            },
            {
                title: "Customer Name",
                key: 'agency_name',
                dataIndex: 'name',
                // sortable: true,
                width: 250,
                render: (item: any) => {
                    return <div className={'facility-details-wrapper'}>
                        {item?.customer_name ?
                            <div>
                                {item?.customer_name?.length >20 ?
                                    <ToolTipComponent
                                        tooltip={item?.customer_name}
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {item?.customer_name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div>
                                        {item?.customer_name}
                                    </div>}
                            </div>
                        :
                            <div>
                                -
                            </div>
                        }
                    </div>;
                }
            },

            {
                title: "Customer Email ID",
                key: 'customer_email_id',
                dataIndex: 'customer_email_id_name',
                // sortable: true,
                width: 300,
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        {item?.email}
                    </div>;
                }
            },
            {
                title: "Default NC Email ID",
                key: 'nc_email_id',
                dataIndex: 'nc_email_id_name',
                // sortable: true,
                width: 300,
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        {item?.nc_email || "-"}
                    </div>;
                }
            },
            {
                title: "Support Phone Number",
                key: 'support_contact_number',
                dataIndex: 'support_contact_number',
                align: 'left',
                width: 200,
                render: (item: any) => {
                    return <>
                        <div>
                            {item?.support_contact_number || "-"}
                        </div>
                    </>;
                }
            },
            {
                title: "Address",
                key: 'address',
                dataIndex: 'address',
                align: 'left',
                width: 250,
                render: (item: any) => {
                    let addressDetails = item?.address
                        ? [
                            item.address.street,
                            item.address.city,
                            item.address.state,
                            item.address.region,
                            item.address.country,
                            item.address.zip_code,
                        ]
                            .filter((value) => value)
                            .join(", ")
                        : "-";
                    return <>
                    {addressDetails?.length > 20 ?
                        <ToolTipComponent
                            tooltip={addressDetails}
                            showArrow={true}
                            position={'top'}
                        >
                            <div className={"ellipses-for-table-data property-name"}>
                                {addressDetails}
                            </div>
                        </ToolTipComponent>:
                        <div>{addressDetails}</div>}
                    </>;
                }
            },
            {
                title: "Code",
                key: 'notification-preference',
                dataIndex: 'notification-preference',
                align: 'left',
                width: 200,
                render: (item: any) => {
                    return <>
                        <div>
                            {item?.customer_unique_id}
                        </div>
                    </>;
                }
            },
            {
                title: "Is Account Module Visible?",
                key: 'account-module-req',
                dataIndex: 'account-module-req',
                align: 'left',
                width: 200,
                render: (item: any) => {
                    return <>
                        <div>
                            {item?.is_acc_module_req ? "Yes": "No"}
                        </div>
                    </>;
                }
            },
        ];
    }, []);

    // const closeEditDrawer = useCallback(() => {
    //     setIsEditUserDetailsDrawerOpen(false);
    // }, []);

    return (
        <>
            {/*<DrawerComponent isOpen={isEditUserDetailsDrawerOpen} direction={"right"} onClose={closeEditDrawer}>*/}
            {/*    <EditCustomerComponent filterData={activeTab === "facility-user" ? filterData : agencyFilterData}*/}
            {/*                           setFilterData={activeTab === "facility-user" ? setFilterData : setAgencyFilterData}*/}
            {/*                           userDetails={userDetails}*/}
            {/*                           closeEditDrawer={closeEditDrawer}*/}
            {/*                           selectedUserType={activeTab}*/}
            {/*    />*/}
            {/*</DrawerComponent>*/}
            <DialogComponent cancel={() => {
                setImagePreview(false)
            }} open={imagePreview}>

                <div>
                    <img width={500} height={500} src={imagePreviewUrl} alt=""/>
                </div>

            </DialogComponent>
            <div className={'user-management-list-component header-screen mrg-top-25 mrg-left-25 mrg-right-30'}>
                <div className="list-screen-header-wrapper">
                    <div className="list-screen-header">
                        {/*<div className={'mrg-bottom-5 home-text'}>Home</div>*/}
                        <div className="list-screen-header-title-and-count d-flex">
                            <div className={'mrg-right-10'}>
                                <ImageConfig.CustomerModuleHeaderIcon/>
                            </div>
                            <div className="list-screen-header-title">Customers</div>
                        </div>
                    </div>
                    <div className="list-options">

                        <div className={"mrg-top-20"}>
                            <SearchComponent
                                size={'medium'}
                                placeholder="Search customer name"
                                value={filterData.search}
                                onSearchChange={(value) => {
                                    setFilterData({...filterData, search: value, page: 1});
                                }}
                            />
                        </div>
                        {currentUser?.role === 'super_admin' &&
                            <LinkComponent route={CommonService._routeConfig.AddCustomerManagementRoute()}>
                                <ButtonComponent color={'primary'} prefixIcon={<ImageConfig.AddOutlinedIcon/>}>
                                    Add New Customer
                                </ButtonComponent>
                            </LinkComponent>
                        }
                    </div>
                </div>
                <div className={'facility-list-component list-screen mrg-top-20'}>
                    <div className="list-screen-content">
                        <TableWrapperComponent url={APIConfig.CUSTOMER_MANAGEMENT_LIST.URL}
                                               method={APIConfig.CUSTOMER_MANAGEMENT_LIST.METHOD}
                                               isPaginated={true}
                                               extraPayload={filterData}
                                               noDataText={filterData.search ? 'Oops! It seems like there are no customer available for the name you have searched.' : 'No Customer Data Found.'}
                                               columns={CustomerManagementListColumn}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
export default CustomerManagementListComponent;
    //
    //
    // {addressDetails?
    //     <div>
    //         {addressDetails?.length > 20 ?
    //             <ToolTipComponent
    //                 tooltip={addressDetails}
    //                 showArrow={true}
    //                 position={'top'}
    //             >
    //                 <div className={'ellipses-for-table-data property-name'}>
    //                     {addressDetails}
    //                 </div>
    //             </ToolTipComponent> :
    //             <div>
    //                 {addressDetails}
    //             </div>
    //         }
    //     </div> :
    //     <div>-</div>}
    //
    // let addressDetails = item?.address
    //     ? [
    //         item.address.street,
    //         item.address.city,
    //         item.address.state,
    //         item.address.region,
    //         item.address.country,
    //         item.address.zip_code,
    //     ]
    //         .filter((value) => value)
    //         .join(", ")
    //     : "-";
    // return (
    //     <div></div>
    // )